* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}

html,
body {
  width: 100vw;
  background: #f5f5f5;
  overflow: hidden;
}

body, input, button, .form-label {
  font-family: "opensans", sans-serif;
  font-weight: 900;
  color: #282828;
}

h1, h2, h3, h4, h5 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}

textarea {
  resize: none;
}

strong {
  font-weight: 600;
}

label {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #282828;
  text-align: center;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

.accordion .card {
  overflow: visible !important;
}

.accordion .card .card-header {
  cursor: pointer;
}

.menu-outer-zindex {
  z-index: 10;
}

.menu-outer-top .select__menu,
.menu-outer-bottom .select__menu,
.ant-select-dropdown {
  z-index: 20 !important;
}
.menu-outer-top .select__menu,
.menu-outer-top .select__menu > .select__menu-list,
.menu-outer-bottom .select__menu,
.menu-outer-bottom .select__menu > .select__menu-list {
  max-height: 130px !important;
}
.menu-outer-top .select__menu {
  top: auto !important;
  bottom: 38px !important;
}

.select__control {
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}

.menu-outer-top .select__control--is-focused,
.menu-outer-top .select__control--is-focused:hover,
.menu-outer-bottom .select__control--is-focused,
.menu-outer-bottom .select__control--is-focused:hover {
  border-color: #000 !important;
  box-shadow: 0 0 0 1px #000 !important;
}
.menu-outer-top.is-invalid .select__control,
.menu-outer-top.is-invalid .select__control:hover,
.menu-outer-bottom.is-invalid .select__control,
.menu-outer-bottom.is-invalid .select__control:hover {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 1px #dc3545 !important;
}
.menu-outer-top.is-valid .select__control,
.menu-outer-top.is-valid .select__control:hover,
.menu-outer-bottom.is-valid .select__control,
.menu-outer-bottom.is-valid .select__control:hover {
  border-color: #28a745 !important;
  box-shadow: 0 0 0 1px #28a745 !important;
}

.menu-outer-top  .select__option--is-focused,
.menu-outer-bottom  .select__option--is-focused {
  background: #d2d2d2 !important;
}
.menu-outer-top .select__option--is-selected,
.menu-outer-bottom .select__option--is-selected {
  background: #000 !important;
}

.animation-inline {
  margin-right: 10px;
}

.form-check:not(:disabled),
.form-check:not(:disabled) > label {
  cursor: pointer;
}

.form-check,
.form-check > label {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.form-check.full,
.form-check.full > label {
  text-align: left;
  display: block;
}

.form-check > label {
  display: inline-block;
  color: #fff;
  padding: 6px 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  opacity: .5;
	filter: alpha(opacity=50);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	-moz-opacity: .5;
	-khtml-opacity: .5;
	-o-opacity: .5;
	-webkit-opacity: .5;
}
@media (max-width: 992px) {
  .form-check,
  .form-check > label {
    width: 100%;
  }
}

.hide {
  display: none !important;
}

.vis-hide {
  visibility: hidden;
}

.bg-dark {
  background-color: #000000 !important;
}

.btn {
  padding: 4px 15px !important;
  font-size: 12px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.btn.full {
  width: 100%;
}
@media (max-width: 992px) {
  .btn {
    width: 100%;
  }
}

.btn-fb {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}
.btn-fb:hover {
  color: #fff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important;
  opacity: 1;
	filter: alpha(opacity=100);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-moz-opacity: 1;
	-khtml-opacity: 1;
	-o-opacity: 1;
	-webkit-opacity: 1;
}
.btn-fb.focus,
.btn-fb:focus {
  box-shadow: 0 0 0 .2rem rgba(82,88,93,.5) !important;
}
.btn-fb.disabled,
.btn-fb:disabled {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-dark,
.form-check-dark > label {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}
.btn-dark:hover,
.form-check-dark > label:hover,
.form-check-dark > input[type="radio"]:checked + label,
.form-check-dark > input[type="checkbox"]:checked + label {
  color: #fff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important;
  opacity: 1;
	filter: alpha(opacity=100);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-moz-opacity: 1;
	-khtml-opacity: 1;
	-o-opacity: 1;
	-webkit-opacity: 1;
}
.btn-dark.focus,
.btn-dark:focus,
.form-check-dark > label.focus,
.form-check-dark > label:focus {
  box-shadow: 0 0 0 .2rem rgba(82,88,93,.5) !important;
}
.btn-dark.disabled,
.btn-dark:disabled,
.form-check-dark > label.disabled,
.form-check-dark > label:disabled {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-light,
.form-check-light > label {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}
.btn-light:hover,
.form-check-light > label:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
}
.btn-light.focus,
.btn-light:focus,
.form-check-light > label.focus,
.form-check-light > label:focus {
  box-shadow:0 0 0 .2rem rgba(216,217,219,.5) !important;
}
.btn-light.disabled,
.btn-light:disabled,
.form-check-light > label.disabled,
.form-check-light > label:disabled {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.btn-danger,
.form-check-danger > label {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.btn-danger:hover,
.form-check-danger > label:hover,
.form-check-danger > input[type="radio"]:checked + label,
.form-check-danger > input[type="checkbox"]:checked + label {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
  opacity: 1;
	filter: alpha(opacity=100);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-moz-opacity: 1;
	-khtml-opacity: 1;
	-o-opacity: 1;
	-webkit-opacity: 1;
}
.btn-danger.focus,
.btn-danger:focus,
.form-check-danger > label.focus,
.form-check-danger > label:focus {
  box-shadow: 0 0 0 .2rem rgba(225,83,97,.5) !important;
}
.btn-danger.disabled,
.btn-danger:disabled,
.form-check-danger > label.disabled,
.form-check-danger > label:disabled {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.form-label {
  font-size: 15px;
}

.default-link-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
	filter: alpha(opacity=0);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-moz-opacity: 0;
	-khtml-opacity: 0;
	-o-opacity: 0;
	-webkit-opacity: 0;
}

.form-control,
.react-datepicker-form-control {
  font-weight: 300;
  line-height: 1.5;
  background-color: #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}
.react-datepicker-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.react-datepicker-form-control:disabled {
  background: #e9ecef !important;
}

.input-group .react-datepicker-form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group .react-datepicker-form-control {
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
  border-left: 0;
}
.input-group-text {
  background: #fff;
}

.default-form-button {
  margin: 15px 0 0 0;
  text-align: right;
}

.iframe-fluid {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #eee;
}

.section-default {
  padding: 50px 15px 50px 15px;
}
.section-content {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  position: relative;
}
.section-content-center {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.default-title {
  padding: 0;
  margin: 0;
  font-size: 46px;
  font-weight: 600;
  line-height: 1.5;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -1px;
}
.default-title.slider {
  font-size: 46px;
  max-width: 100vw;
  margin: auto;
  letter-spacing: -2px;
  line-height: 1;
  text-transform: none;
}
.default-title.slider > .default-title-sub-a,
.default-title.slider > .default-title-sub-b {
}
.default-title.slider > .default-title-sub-a {
  font-weight: bold;
  font-size: 46px;
  letter-spacing: -2px;
}
@media (max-width: 992px) {
  .default-title.slider > .default-title-sub-a {
    font-size: 36px;
  }
}
.default-title.slider > .default-title-sub-b {
  font-weight: 300;
  font-size: 40px;
  letter-spacing: 10px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .default-title.slider > .default-title-sub-b {
    font-size: 30px;
  }
}

.default-sub-title {
  padding: 0;
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -1px;
}
.default-sub-title.light {
  color: #ffffff;
}
.default-sub-title-separator {
  padding-top: 15px;
  margin: auto;
  margin-bottom: 100px;
  border-bottom: 2px solid rgba(82,88,93,.5);
  width: 100px;
  display: block;
}
.default-sub-title-separator.half {
  margin-bottom: 50px;
}
.default-sub-title-separator.light {
  border-bottom: 2px solid rgba(255,255,255,.5);
}

.default-description {
  padding: 0;
  margin: 0;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  letter-spacing: 2px;
}
.default-description.slider {
  padding: 50px 0 0 0;
  font-size: 25px;
  max-width: 65vw;
  margin: auto;
  letter-spacing: -1px;
  line-height: 1;
}
@media (max-width: 992px) {
  .default-description.slider {
    font-size: 20px;
  }
}
.default-description.slider.slider-col {
  font-size: 20px;
}
@media (max-width: 992px) {
  .default-description.slider.slider-col {
    font-size: 15px;
  }
}

.default-sub-description {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #777;
  text-align: justify;
}
.default-sub-description.light {
  color: #ffffff;
}

.justify-content-center {
  justify-content: center !important;
}
.reset-padding {
  padding: 0 !important;
}
@media (min-width: 768px) {
  .reset-padding-md {
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .reset-padding-xs {
    padding: 0 !important;
  }
}
.reset-padding-top {
  padding-top: 0 !important;
}
@media (min-width: 768px) {
  .reset-padding-top-md {
    padding-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .reset-padding-top-xs {
    padding-top: 0 !important;
  }
}
.reset-padding-right {
  padding-right: 0 !important;
}
@media (min-width: 768px) {
  .padding-right-5-md {
    padding-right: 7.5px !important;
  }
  .reset-padding-right-md {
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .reset-padding-right-xs {
    padding-right: 0 !important;
  }
}
.reset-padding-bottom {
  padding-bottom: 0 !important;
}
@media (min-width: 768px) {
  .reset-padding-bottom-md {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 767px) {
  .margin-bottom-15-xs {
    margin-bottom: 15px !important;
  }
  .reset-padding-bottom-xs {
    padding-bottom: 0 !important;
  }
}
.reset-padding-left {
  padding-left: 0 !important;
}
@media (min-width: 768px) {
  .padding-left-5-md {
    padding-left: 7.5px !important;
  }
  .reset-padding-left-md {
    padding-left: 0 !important;
  }
}
@media (max-width: 767px) {
  .reset-padding-left-xs {
    padding-left: 0 !important;
  }
}

.position-relative {
  position: relative;
}


/* modal */
.modal {
  z-index: 1048;
}
.modal-content {
  top: 30px;
  background-color: #eee;
  border: 1px solid #000;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0,0,0,.4);
  -moz-box-shadow: 1px 1px 10px rgba(0,0,0,.4);
  -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,.4);
}
@media (max-width: 992px) {
  .modal-content {
    top: 40px;
  }
}
@media (max-width: 575px) {
  .modal-content {
    top: 60px;
  }
}
.modal-header {
  background-color: #000;
  position: relative;
}
.modal-header > .close,
.modal-header > .clock-ociosidade {
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  margin: auto;
}
.modal-header > .close {
  padding: 0;
  background: #fff;
  width: 26px;
  height: 26px;
  top: 0;
  bottom: 0;
  right: 19px;
}
.modal-header > .close > span {
  padding: 0;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 26px;
}
.modal-header > .clock-ociosidade {
  width: 42px;
  height: 42px;
  background: transparent;
  top: 0;
  bottom: 0;
  right: 11px;
}
.modal-header > .clock-ociosidade > .clock-path-shadow  {
  stroke-dasharray: 130 130;
  z-index: 1;
}

.modal-title {
  font-weight: 900;
  color: #fff;
}

/*tab*/
.tab-pane {
  text-align: justify;
}
/* @media (max-width: 575px) {
  .tab-pane {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #d2d2d2;
  }
} */

.nav-item.nav-link {
  padding: .5rem .8rem;
}

.nav-pills .nav-item .nav-link {
  padding-left: 38px;
  position: relative;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .nav-pills .nav-item .nav-link {
    font-size: 0;
  }
}
.nav-pills .nav-item .nav-link.active {
  background-color: #000;
}
.nav-pills .nav-item .nav-link > div {
  padding: 0;
  margin: auto;
  position: absolute;
  border: 1px solid #000;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 24px;
  height: 24px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.nav-pills .nav-item .nav-link.active > div {
  border: 1px solid #fff;
}
@media (max-width: 991px) {
  .nav-pills .nav-item .nav-link > div {
    left: 0;
    right: 0;
  }
}

.nav-pills .nav-item .nav-link > div > i,
.nav-pills .nav-item .nav-link > div > svg {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 13px;
  color: #000;
}
.nav-pills .nav-item .nav-link > div > svg.p2 {
  padding: 0 1px;
}

.card-item-margin {
  margin-top: 15px;
  margin-bottom: 15px;
}

.nav-link-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #808080;
}

.div-total-switch {
  width: 100%;
  height: 55px;
  float: none;
  position: relative;
  display: inline-block;
}
.div-total-switch>.div-description-switch {
  top: 0;
  width: 100%;
  height: 25px;
  text-align: center;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch {
  left: 0;
  right: 0;
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch, .div-total-switch>.div-description-on-off-switch {
  position: absolute;
  margin: auto;
}
.div-total-switch>input[type="checkbox"],
.div-total-switch>input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -o-opacity: 0;
  -webkit-opacity: 0;
  cursor: pointer;
  display: block !important;
}
.div-total-switch>.div-content-switch {
  bottom: 0;
  width: 60px;
  height: 30px;
  border: 1px solid #acaaaa;
  background: #fff;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  box-shadow: inset 1px 1px 10px #d2d2d2;
  -moz-box-shadow: inset 1px 1px 10px #d2d2d2;
  -webkit-box-shadow: inset 1px 1px 10px #d2d2d2;
  transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -webkit-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -moz-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -o-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -ms-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -khtml-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch {
  left: 0;
  right: 0;
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch, .div-total-switch>.div-description-on-off-switch {
  position: absolute;
  margin: auto;
}
.div-total-switch>.div-content-switch>.div-indicator-switch {
  position: absolute;
  top: 1px;
  left: 2px;
  border: 1px solid #acaaaa;
  background: #d2d2d2;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 1px 1px 5px #d2d2d2;
  -moz-box-shadow: 1px 1px 5px #d2d2d2;
  -webkit-box-shadow: 1px 1px 5px #d2d2d2;
  transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -webkit-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -moz-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -o-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -ms-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -khtml-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
}
.div-total-switch>input[type="checkbox"]:checked+.div-content-switch, .div-total-switch>input[type="radio"]:checked+.div-content-switch {
  background: #d2d2d2;
}
.div-total-switch>.div-content-switch {
  bottom: 0;
  width: 60px;
  height: 30px;
  border: 1px solid #acaaaa;
  background: #fff;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  box-shadow: inset 1px 1px 10px #d2d2d2;
  -moz-box-shadow: inset 1px 1px 10px #d2d2d2;
  -webkit-box-shadow: inset 1px 1px 10px #d2d2d2;
  transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -webkit-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -moz-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -o-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -ms-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -khtml-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch {
  left: 0;
  right: 0;
}
.div-total-switch>.div-description-switch, .div-total-switch>.div-content-switch, .div-total-switch>.div-description-on-off-switch {
  position: absolute;
  margin: auto;
}
.div-total-switch>input[type="checkbox"]:checked+.div-content-switch>.div-indicator-switch, .div-total-switch>input[type="radio"]:checked+.div-content-switch>.div-indicator-switch {
  left: 30px;
  background: #acaaaa;
  border-color: #808080;
}
.div-total-switch>.div-content-switch>.div-indicator-switch {
  position: absolute;
  top: 1px;
  left: 2px;
  border: 1px solid #acaaaa;
  background: #d2d2d2;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 1px 1px 5px #d2d2d2;
  -moz-box-shadow: 1px 1px 5px #d2d2d2;
  -webkit-box-shadow: 1px 1px 5px #d2d2d2;
  transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -webkit-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -moz-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -o-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -ms-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
  -khtml-transition: all 500ms cubic-bezier(.250,.100,.250,1.000);
}

.show {
  display: block !important;
}

/* select2 */
.select2-container--bootstrap .select2-selection {
  padding: .375rem .75rem !important;
  height: 38px !important;
  border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.invalid .select2-container--bootstrap .select2-selection {
  border-color: #dc3545 !important;
}
.invalid .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-color: #dc3545 transparent transparent !important;
}


.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url('data:image/svg+xml,<svg class="svg-inline--fa fa-check-circle fa-w-16" style="color: rgb(40, 167, 69);" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>');
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url('data:image/svg+xml,<svg class="svg-inline--fa fa-exclamation-circle fa-w-16" style="color: rgb(220, 53, 69);" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>');
}


.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected {
  background-color: #000 !important;
  color: #fff !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range  {
  background-color: #000 !important;
  color: #fff !important;
}
.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #000 !important;
  color: #fff !important;
}

.react-datepicker {
  display: flex !important;
  flex-wrap: wrap !important;
  border: 1px solid #ced4da !important;
}

.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before {
  border-bottom-color: #ced4da !important;
}

.react-datepicker__month-container,
.react-datepicker__time-container {
  overflow: auto;;
}

.react-datepicker__month-container {
  flex: 1;
  flex-direction: column;
}
.react-datepicker__header {
  padding-top: 5px !important;
  height: 92px;
  display: flex;
  flex-direction: column;
  background: #eee !important;
  border-bottom: 1px solid #ced4da !important;
}

.react-datepicker__navigation--previous {
  left: 5px !important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  padding: 5px;
  width: 22px !important;
  height: 22px !important;
  top: 5px !important;
  background: #000 !important;
  border: 0 !important;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  opacity: .5;
	filter: alpha(opacity=50);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	-moz-opacity: .5;
	-khtml-opacity: .5;
	-o-opacity: .5;
	-webkit-opacity: .5;
}
.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button):hover {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -o-opacity: 1;
  -webkit-opacity: 1;
}

.react-datepicker__navigation--previous:before,
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.react-datepicker__current-month {
  margin-bottom: 5px !important;
}

.react-datepicker__navigation--previous:before {
  border-left: none;
  border-right-color: #fff;
  right: 9px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button):before {
  border-right: none;
  border-left-color: #fff;
  left: 9px;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
  padding: 0 5px;
  background: rgba(0,0,0,.5);
  color: #fff;
}
.react-datepicker__month-read-view:hover,
.react-datepicker__year-read-view:hover {
  background: rgba(0,0,0,1);
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  position: absolute;
  width: 200px !important;
  max-height: 260px !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  overflow: auto !important;
}
.react-datepicker__month-option,
.react-datepicker__year-option {
  padding: 5px;
  background-color: #fff !important;
}
.react-datepicker__month-option:hover,
.react-datepicker__month-option--selected_month,
.react-datepicker__year-option:hover,
.react-datepicker__year-option--selected_year {
  background-color: #000 !important;
  color: #fff !important;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #fff !important;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #fff !important;
}


.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 10px !important;
  border-width: 5px !important;
  margin-left: 8px !important;
  margin-bottom: 0 !important;
}

.react-datepicker__month {
  flex: 1;
}

.react-datepicker__time-container {
  width: 55px !important;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ced4da !important;
}
.react-datepicker__time {
  flex: 1;
  -webkit-border-bottom-right-radius: 5px !important;
  -moz-border-radius-bottomright: 5px !important;
  border-bottom-right-radius: 5px !important;
  display: flex;
}

.react-datepicker__header--time {
  padding: 0 !important;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker-time__header {
  font-size: 14px !important;
}

.react-datepicker__time-box {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}
.react-datepicker__time-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 268px !important;
}

.qrcode {
  text-align: center;
}

.qrcode canvas {
  margin: auto;
}

.icon-anchor-status-ok,
.icon-anchor-status-violated {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -4px;
  right: -4px;
  line-height: 0;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}

.icon-anchor-status-ok {
  background-color: #087d06;
}

.icon-anchor-status-violated {
  background-color: #ff0000;
}
.icon-anchor-status-ok svg,
.icon-anchor-status-violated svg {
  color: #ffffff !important;
  font-size: 8px !important;
}
