.section-content-qrcode {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0,0,0,.5);
  display: block;
}

.data-qrcode {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 300px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #dee2e6;
  width: 320px;
  height: 435px;
  z-index: 1;
  border-radius: .25rem;
  -moz-border-radius: .25rem;
  -webkit-border-radius: .25rem;
  box-shadow: 1px 1px 10px rgba(0,0,0,.4);
  -moz-box-shadow: 1px 1px 10px rgba(0,0,0,.4);
  -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,.4);
}

.section-content-qrcode .data-qrcode-controls {
  padding: 0 15px;
  width: 100%;
  height: 115px;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
}
.section-content-qrcode .data-qrcode-controls button:first-child {
  margin-right: 15px;
}
.section-content-qrcode .data-qrcode-controls button {
  max-width: 100px;
}

.data-qrcode-content {
  height: 320px;
  background-color: #fff;
  overflow: auto;
  text-align: center;
  display: flex;
}
.data-qrcode-content canvas {
  margin: auto;
}
